import { tns } from '~/node_modules/tiny-slider/src/tiny-slider';

var navToggle = document.querySelector('.nav__trigger');
var navContain = document.querySelector('.nav .contain');

if (navToggle) {
  navToggle.addEventListener('click', () => {
    navContain.classList.toggle('nav--on');
    navToggle.classList.toggle('animate');
  });
}

var sliderForEach = function (array, callback, scope) {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]); // passes back stuff we need
  }
};

let cardSliders = document.querySelectorAll('.card-slider');
  
sliderForEach(cardSliders, function (index, value) {
  
  let sliderContainer = value.id;

  tns({
    container: '#' + sliderContainer + ' .slides',
    items: 1,
    slideBy: 'page',
    loop: false,
    nav: true,
    navPosition: 'bottom',
    controlsContainer: '#' + sliderContainer + ' .controls',
    edgePadding: 40,
    gutter: 44,
    responsive: {
      640: {
        items: 2,
      },
      900: {
        items: 3,
        edgePadding: 60,
        gutter: 60,
      },
    },
  });
}); 

let heroSliders = document.querySelectorAll('.hero-slider');
  
sliderForEach(heroSliders, function (index, value) {
  
  var sliderContainer = value.id;

  tns({
    container: '#' + sliderContainer + ' .slides',
    items: 1,
    slideBy: 'page',
    loop: false,
    nav: false,
    controlsContainer: '#' + sliderContainer + ' .controls',
  });
}); 

let bannerSliders = document.querySelectorAll('.banner-slider');
  
sliderForEach(bannerSliders, function (index, value) {
  
  let sliderContainer = value.id;

  tns({
    container: '#' + sliderContainer + ' .slides',
    items: 1,
    slideBy: 'page',
    loop: false,
    nav: false,
    controlsContainer: '#' + sliderContainer + ' .controls',
  });
}); 


let imageSliders = document.querySelectorAll('.image-slider');
  
sliderForEach(imageSliders, function (index, value) {
  
  let sliderContainer = value.id;

  tns({
    container: '#' + sliderContainer + ' .slides',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    autoplayButtonOutput: false,
    nav: true,
    navPosition: 'bottom',
    controlsContainer: '#' + sliderContainer + ' .controls',
  });
}); 

var categoryFilter = document.getElementById('category-filter');

if (categoryFilter) {
  categoryFilter.onchange = function () {
    var categoryUrl = this.value;
    if (categoryUrl != window.location.href) {
      window.location = categoryUrl;
    }
  };
}
